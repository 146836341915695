<template>
  <div>
    <div class="note-card">
      <div style="flex: 1">
        <div class="note-star" @click.stop="$emit('saveLike', item)">
          <div class="note-star-show">
            <div class="thumbs" v-show="item.user_liked">
              <i class="fas fa-heart" style="color: pink"></i>
            </div>
            <div class="thumbs" v-show="!item.user_liked">
              <i class="far fa-heart"></i>
            </div>
            <div>{{ item.user_liked_count }}</div>
          </div>
          <template v-if="isMy">
            <el-button
              size="mini"
              type="primary"
              @click.stop="$emit('editMyMnemonic', index)"
            >
              <i class="fas fa-edit"></i>
            </el-button>
          </template>
        </div>
        <div class="note-content">
          <div class="note-content-text" v-if="item.mnemonic !== ''">
            <a
              v-if="checkUrl(item.mnemonic)"
              :href="item.mnemonic"
              target="_blank"
            >
              {{ item.mnemonic }}&nbsp;
            </a>
            <span v-else> {{ item.mnemonic }}&nbsp; </span>
          </div>
          <div
            class="note-content-img"
            @click.stop=""
            v-if="
              item.mnemonic_img_url &&
                item.mnemonic_img_url !== '' &&
                isImage(item.mnemonic_img_url)
            "
          >
            <el-image
              :src="item.mnemonic_img_url"
              :preview-src-list="[item.mnemonic_img_url]"
            >
            </el-image>
          </div>
          <div
            class="note-content-img"
            @click.stop=""
            v-if="item.mnemonic_img_url && item.mnemonic_img_url !== ''"
          >
            <video
              class="my-video"
              v-if="
                item.mnemonic_img_url &&
                  item.mnemonic_img_url !== '' &&
                  isVideo(item.mnemonic_img_url)
              "
              :src="item.mnemonic_img_url"
              controls
            ></video>
          </div>
        </div>
      </div>
      <!-- <div class="card-name" v-if="item.is_hidden === 0">
        <small> {{ item.user.first_name }} {{ item.user.last_name }} </small>
        
      </div> -->
      <div class="card-name">
       
        <small class="starIsSelected" v-if="isMy">
          From: {{ $t("flashCards.Me") }}
        </small>
        <small v-else class="text-success">
          From: {{ this.CompanyName }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import mixins from "@/views/flashCards/mixins/index.js";


export default {
  metaInfo() {},

  components: {},

  mixins: [mixins],

  props: ["isIvyway", "item", "itemIvyway", "isMy", "index"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    checkUrl(link) {
      var reg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/;
      if (!reg.test(link)) {
        return false;
      } else {
        return true;
      }
    },
    urltoFile(url, filename, mimeType) {
      return fetch(url)
        .then(function(res) {
          return res.arrayBuffer();
        })
        .then(function(buf) {
          return new File([buf], filename, { type: mimeType });
        });
    },
    resizeImage(img, maxWidth, maxHeight) {
      let newWidth = img.width;
      let newHeight = img.height;
      if (img.width > img.height && img.width > maxWidth) {
        newHeight = Math.floor(img.height * (maxWidth / img.width));
        newWidth = maxWidth;
      } else if (img.height > maxHeight) {
        newHeight = maxHeight;
        newWidth = Math.floor(img.width * (maxHeight / img.height));
      }
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      return canvas.toDataURL("image/jpeg");
    }
  }
};
</script>

<style scoped>
.my-video {
  margin-top: 20px;
  width: 400px;
}
.note-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  padding: 0 1rem;
  /* border: 1px solid #ebeef5; */
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  width: 20rem;
  height: 15rem;
  margin-right: 1rem;
}
.note-card .card-name {
  height: 3rem;
  line-height: 3rem;
  text-align: right;
  font-weight: 700;
}
.card-name small {
  font-weight: 700;
}
.note-star {
  display: flex;
  font-size: 1.3rem;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}
.note-star-show {
  display: flex;
  align-items: center;
}
.thumbs {
  margin-right: 0.5rem;
}
.note-content {
  flex-direction: column;
  display: flex;
  height: 9rem;
  overflow: hidden;
}
.note-content small {
  font-weight: normal;
  margin-top: 0;
  display: inline-block;
}
.starIsSelected {
  color: orange;
  cursor: pointer;
}

::v-deep .el-image .el-image__preview {
  cursor: zoom-in;
}
::v-deep .el-image:hover {
  opacity: 0.7;
}
::v-deep .el-image .el-image__inner,
::v-deep .el-image img {
  height: auto;
  max-height: 400px;
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .note-card {
    width: 15rem;
  }
  ::v-deep .note-star .el-button {
    margin-top: 0;
  }
}
</style>
