import ApiService from "@/common/api.service";
export default {
  createVocabList(data) {
    return ApiService.post("vocab-collects", data);
  },
  saveVocabList(id,data){
    return ApiService.patch(`vocab-collects/${id}`, data);
  },
  removeFile(data){
    console.log(data);
    return ApiService.delete("mnemonics/file", data);
  },
  deleteVocabList(id){
    return ApiService.delete(`vocab-collects/${id}`);
  },
  getVocabLists() {
    return ApiService.query("vocab-collects");
  },
  collectVocab(vocabCollect,data) {
    return ApiService.query("vocab-collects/" + vocabCollect , data);
  },
  getCollectVocab(data){
    return ApiService.query("vocab-collects/list", data);
  },
  addVocabToList(vocabCollect,data) {
    return ApiService.post(`vocab-collects/${vocabCollect}/vocab`, data);
  },
  deleteVocabFromList(vocabCollect,vocabFront) {
    return ApiService.delete(`vocab-collects/${vocabCollect}/vocab/${vocabFront}`);
  }
};
