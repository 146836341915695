var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"note-card"},[_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"note-star",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('saveLike', _vm.item)}}},[_c('div',{staticClass:"note-star-show"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.user_liked),expression:"item.user_liked"}],staticClass:"thumbs"},[_c('i',{staticClass:"fas fa-heart",staticStyle:{"color":"pink"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.item.user_liked),expression:"!item.user_liked"}],staticClass:"thumbs"},[_c('i',{staticClass:"far fa-heart"})]),_c('div',[_vm._v(_vm._s(_vm.item.user_liked_count))])]),(_vm.isMy)?[_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('editMyMnemonic', _vm.index)}}},[_c('i',{staticClass:"fas fa-edit"})])]:_vm._e()],2),_c('div',{staticClass:"note-content"},[(_vm.item.mnemonic !== '')?_c('div',{staticClass:"note-content-text"},[(_vm.checkUrl(_vm.item.mnemonic))?_c('a',{attrs:{"href":_vm.item.mnemonic,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.item.mnemonic)+"  ")]):_c('span',[_vm._v(" "+_vm._s(_vm.item.mnemonic)+"  ")])]):_vm._e(),(
            _vm.item.mnemonic_img_url &&
              _vm.item.mnemonic_img_url !== '' &&
              _vm.isImage(_vm.item.mnemonic_img_url)
          )?_c('div',{staticClass:"note-content-img",on:{"click":function($event){$event.stopPropagation();}}},[_c('el-image',{attrs:{"src":_vm.item.mnemonic_img_url,"preview-src-list":[_vm.item.mnemonic_img_url]}})],1):_vm._e(),(_vm.item.mnemonic_img_url && _vm.item.mnemonic_img_url !== '')?_c('div',{staticClass:"note-content-img",on:{"click":function($event){$event.stopPropagation();}}},[(
              _vm.item.mnemonic_img_url &&
                _vm.item.mnemonic_img_url !== '' &&
                _vm.isVideo(_vm.item.mnemonic_img_url)
            )?_c('video',{staticClass:"my-video",attrs:{"src":_vm.item.mnemonic_img_url,"controls":""}}):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"card-name"},[(_vm.isMy)?_c('small',{staticClass:"starIsSelected"},[_vm._v(" From: "+_vm._s(_vm.$t("flashCards.Me"))+" ")]):_c('small',{staticClass:"text-success"},[_vm._v(" From: "+_vm._s(this.CompanyName)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }