<template>
  <div class="box" id="box">
    <div>
      <div class="flashcardHeader d-flex">
        <div class="return">
          <div
            v-if="!showNotes"
            @click="goBack"
            style="color: var(--themeColor); cursor: pointer;width:40px"
          >
            <i class="fas fa-chevron-left"></i>
          </div>
          <div
            v-else
            @click="showNotes = false"
            style="color: var(--themeColor); cursor: pointer"
          >
            <i class="fas fa-chevron-left"></i>
          </div>
        </div>
        <div class="headerTitle">
          <span v-if="currentSelectedContent[0]">
            {{ currentSelectedContent[0].word }}
          </span>
        </div>
        <div class="remaining-progress">
          <el-progress
            v-if="currentSelectedContent[0]"
            type="circle"
            :color="themeColor"
            :percentage="(totalLength / originLength) * 100"
            :width="40"
            :format="
              () => {
                return totalLength;
              }
            "
          ></el-progress>
        </div>
      </div>
      <div class="flashcardHeaderSecond d-flex">
        <div>{{ title }}</div>
      </div>
    </div>

    <template v-if="!finished">
      <div class="words-cards-box">
        <div class="words-cards">
          <div
            class="words-cards-bottom"
            v-for="card in currentSelectedContent.length - 1"
            :key="card"
            :style="
              `transform: translate(${card * 0.5}rem,${card *
                0.5}rem);z-index:-${card} `
            "
          ></div>
          <template v-if="!showNotes">
            <div class="controlRow">
              <div class="remove" style="width: 10%;" @click="removeWord">
                <i class="fas fa-times"></i>
              </div>
              <div v-show="showDetail" class="words-button">
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    @click.stop="
                      speakWord(currentSelectedContent[0].word, 'en-US')
                    "
                    >US&nbsp;<i class="fas fa-volume-up"></i
                  ></el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    @click.stop="
                      speakWord(currentSelectedContent[0].word, 'en-GB')
                    "
                    >UK&nbsp;<i class="fas fa-volume-up"></i
                  ></el-button>
                </div>
              </div>
              <div class="star"  style="width: 10%;">
                <!-- <div class="mr-2" @click="starDrawer = true"> -->
                <div
                  class="mr-2"
                  @click="starWord(currentSelectedContent[0].id)"
                >
                  <template v-if="showStared">
                    <div>
                      <i class="fas fa-star starIsSelected"></i>
                    </div>
                  </template>
                  <template v-else>
                    <div>
                      <i class="far fa-star starHover"></i>
                    </div>
                  </template>
                </div>
              </div>
              <el-drawer
                title="Add to my favorites"
                :visible.sync="starDrawer"
                direction="rtl"
                size="70%"
              >
                <div class="starLists">
                  <template v-if="lists && lists.length > 0">
                    <div
                      class="list"
                      v-for="(list, index) in lists"
                      :key="index"
                    >
                      <div class="list-content">
                        <div class="list-title">{{ list.title }}</div>
                        <div>
                          <span
                            v-show="stared(list.id)"
                            @click="removeStar(list.id)"
                            ><i class="fas fa-star starIsSelected"></i
                          ></span>
                          <span
                            v-show="!stared(list.id)"
                            @click="addStar(list.id)"
                            ><i class="far fa-star starHover"></i
                          ></span>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </template>
                  <div class="list" @click="showNewList">
                    <div class="list-content">
                      Add New<span
                        ><i class="el-icon-plus avatar-uploader-icon"></i
                      ></span>
                    </div>
                    <hr />
                  </div>
                </div>
              </el-drawer>
            </div>
            <div
              class="wordDisplay"
              v-if="currentSelectedContent[0] && totalLength > 0"
              @click="toggleDetail(currentSelectedContent[0])"
            >
              <div v-show="!showDetail" class="text-center">
                <div style="margin-top:20px">
                  {{ currentSelectedContent[0].word }}
                </div>
                <div class="voice">
                  <el-button
                    type="primary"
                    style="font-size:20px;margin-right:20px"
                    @click.stop="
                      speakWord(currentSelectedContent[0].word, 'en-US')
                    "
                    >US&nbsp;<i class="fas fa-volume-up"></i
                  ></el-button>
                  <el-button
                    type="primary"
                    style="font-size:20px"
                    @click.stop="
                      speakWord(currentSelectedContent[0].word, 'en-GB')
                    "
                    >UK&nbsp;<i class="fas fa-volume-up"></i
                  ></el-button>
                </div>
              </div>
              <div v-if="showDetail" class="wordDetail">
                <div class="memorization-technique  word-section">
                  <div class="section-title">
                    <i class="fas fa-brain"></i>
                    {{ $t("flashCards.Memorization technique") }}
                    <el-button
                      type="success"
                      size="mini"
                      @click.stop="createNewMnemonic"
                    >
                      <i class="fas fa-plus"></i>
                    </el-button>
                  </div>
                  <div class="mnemonics-cards-list">
                    <template v-if="myMnemonics.length > 0">
                      <Mnemonic
                        v-for="(item, index) in myMnemonics"
                        :key="index"
                        :item="item"
                        :index="index"
                        :isMy="true"
                        @saveLike="saveLike"
                        @editMyMnemonic="editMyMnemonic"
                      />
                    </template>
                    <template v-if="mnemonics.length > 0">
                      <Mnemonic
                        v-for="(item, index) in mnemonics"
                        :key="index"
                        :item="item"
                        :index="index"
                        :isMy="false"
                        @saveLike="saveLike"
                      />
                    </template>
                  </div>
                </div>
                <hr />
                <div class="meaning word-section">
                  <div class="section-title">
                    <i class="fas fa-book book"></i>
                    {{ $t("flashCards.Meaning") }}
                  </div>
                  <div
                    class="mt-3 mb-3 note-card"
                    v-for="meaning in currentSelectedContent[0]
                      .word_definitions"
                    :key="meaning.id"
                  >
                    <div class="note-content pt-2 pb-2">
                      <div>
                        <span v-if="meaning.pos">( {{ meaning.pos }} )</span>
                        {{ meaning.definition.en }}
                        <span v-if="lang.value === 'ZH-TW'">
                          {{ meaning.definition.tw }}
                        </span>
                        <span v-if="lang.value === 'ZH-CN'">
                          {{ meaning.definition.cn }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="sentence word-section">
                  <div class="section-title">
                    <i class="fas fa-comment"></i>
                    {{ $t("flashCards.Sentence") }}
                  </div>
                  <div
                    class="word-example"
                    v-for="meaning in currentSelectedContent[0]
                      .word_definitions"
                    :key="meaning.id"
                  >
                    {{ meaning.example }}
                  </div>
                </div>
                <hr />
                <div class="i18n48 word-section">
                  <div class="section-title">
                    <i class="fas fa-atlas" /> {{ $t("flashCards.i18n48") }}
                  </div>
                  <div class="dictionaries">
                    <el-tooltip
                      class="item"
                      :content="$t('flashCards.merriam-webster')"
                      placement="top"
                      popper-class="tooltipColor"
                    >
                      <a
                        target="_blank"
                        :href="getTodictionaryLink('merriam-webster')"
                      >
                        <div class="dictionary">
                          <img
                            src="@/assets/dictionary/merriam-webster.png"
                            class="dictionaryIcon"
                          />
                        </div>
                      </a>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      :content="$t('flashCards.cambridge')"
                      placement="top"
                      popper-class="tooltipColor"
                    >
                      <a
                        target="_blank"
                        :href="getTodictionaryLink('cambridge')"
                      >
                        <div class="dictionary">
                          <img
                            src="@/assets/dictionary/cambridge.png"
                            class="dictionaryIcon"
                          />
                        </div>
                      </a>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      :content="$t('flashCards.youtube')"
                      placement="top"
                      popper-class="tooltipColor"
                    >
                      <a target="_blank" :href="getTodictionaryLink('youtube')">
                        <div class="dictionary">
                          <img
                            src="@/assets/dictionary/youtube.png"
                            class="dictionaryIcon"
                          />
                        </div>
                      </a>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      :content="$t('flashCards.google')"
                      placement="top"
                      popper-class="tooltipColor"
                    >
                      <a target="_blank" :href="getTodictionaryLink('google')">
                        <div class="dictionary">
                          <img
                            src="@/assets/dictionary/google.png"
                            class="dictionaryIcon"
                          />
                        </div>
                      </a>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <NotesList :mnemonics="mnemonics" />
          </template>
        </div>
      </div>
      <!-- three buttons -->
      <div class="levelButtons">
        <div class="d-flex flex-buttons" v-if="!show">
          <div style="width: 100%; color: #d9534f;" class="btnStyle">
            <div style="background-color: #d9534f;" class="btnBar"></div>
            <div class="btnClick" @click="putToRandomPlace('hard')">Hard</div>
          </div>
          <div style="width: 100%; color: #5bc0de;" class="btnStyle">
            <div style="background-color: #5bc0de;" class="btnBar"></div>
            <div class="btnClick" @click="putToRandomPlace('good')">Good</div>
          </div>
          <div style="width: 100%; color: rgb(66, 161, 106);" class="btnStyle">
            <div
              style="background-color: rgb(66, 161, 106);"
              class="btnBar"
            ></div>
            <div class="btnClick" @click="putToRandomPlace('easy')">Easy</div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- <div class="controlRow d-flex mt-4"></div> -->
      <div class="word-finished">
        <div>
          <h3>Your Memorization Summary</h3>
          <h5>
            Total time: {{ Number(totalHour) > 0 ? `${totalHour}:` : ""
            }}{{ totalMinute }}:{{ totalSecond }}
          </h5>
          <h5>Total words: {{ viewWordList.length }}</h5>
          <h5>Total attempts: {{ viewTotal }}</h5>
          <el-tabs type="border-card" style="margin-top:20px">
            <el-tab-pane>
              <div slot="label" class="card-line hard-cards">
                Hard
              </div>
              <ul>
                <li
                  v-for="word in viewWordList"
                  :key="word.vocab_front_id"
                  v-show="word.hard > 0"
                >
                  {{ word.word }}: {{ word.hard }}
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane>
              <div slot="label" class="card-line good-cards">
                Good
              </div>
              <ul>
                <li
                  v-for="word in viewWordList"
                  :key="word.vocab_front_id"
                  v-show="word.good > 0"
                >
                  {{ word.word }}: {{ word.good }}
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane>
              <div slot="label" class="card-line easy-cards">
                Easy
              </div>
              <ul>
                <li
                  v-for="word in viewWordList"
                  :key="word.vocab_front_id"
                  v-show="word.easy > 0"
                >
                  {{ word.word }}: {{ word.easy }}
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane>
              <div slot="label" class="card-line total-cards">
                Total
              </div>
              <ul>
                <li
                  v-for="word in viewWordList"
                  :key="word.vocab_front_id"
                  v-show="word.easy + word.good + word.hard > 0"
                >
                  {{ word.word }}: {{ word.easy + word.good + word.hard }}
                </li>
              </ul>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <!-- two buttons -->
      <div class="levelButtons">
        <div class="d-flex flex-buttons" v-if="show">
          <div style="width: 100%; color: #5bc0de;" class="btnStyle">
            <div style="background-color: #5bc0de;" class="btnBar"></div>
            <div class="btnClick" @click="goBack">Go Back</div>
          </div>
          <div style="width: 100%; color: var(--themeColor);" class="btnStyle">
            <div
              style="background-color: var(--themeColor);"
              class="btnBar"
            ></div>
            <div class="btnClick" @click="restart">Restart</div>
          </div>
        </div>
      </div>
    </template>

    <!-- drawer -->
    <el-drawer
      :visible.sync="drawer"
      :before-close="handleClose"
      direction="rtl"
      :append-to-body="true"
      :size="widthPercent"
    >
      <div class="d-block">
        <div class="drawerItem p-3">
          <h5 class="pt-1">
            <i class="fas fa-cog"></i>
            <span class="ml-3">Settings</span>
          </h5>
        </div>

        <div class="drawerItem p-3">
          <h5 class="pt-1">
            <i class="far fa-question-circle"></i>
            <span class="ml-3">Help</span>
          </h5>
        </div>
      </div>
    </el-drawer>

    <!-- dialog -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :width="widthPercentDialog"
      :before-close="dialogContinue"
      :close-on-click-modal="false"
    >
      <span>{{ $t("flashCards.Do you wish to continue memorizing?") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="dialogLeave">
          {{ $t("flashCards.Quit") }}
        </el-button>
        <el-button type="primary" @click="dialogContinue">
          {{ $t("flashCards.Continue") }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog title="Add" :visible.sync="starDialogVisible" width="92%">
      <div>
        <div class="demo-input-suffix" style="margin-bottom:10px">
          <h6>Title:</h6>
          <el-input placeholder="Title" v-model="activeList.title"> </el-input>
        </div>
        <div class="demo-input-suffix">
          <h6>Description:</h6>
          <el-input
            type="textarea"
            :rows="4"
            placeholder="Description"
            v-model="activeList.intro"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer text-center">
        <el-button type="primary" @click="createList">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('flashCards.My technique')"
      :visible.sync="showEditNote"
      width="95%"
      @close="getMnemonics()"
    >
      <div>
        <el-tabs value="Text" type="border-card">
          <el-tab-pane name="Text">
            <span slot="label"
              ><i class="fas fa-align-center"></i>
              {{ $t("flashCards.Text") }}</span
            >
            <div @click="getAIMnemonics" class="AI-button mb-3">
              <b class="logo-color logo-title">AI</b>
              Technique
            </div>
            <el-input
              type="textarea"
              placeholder="Write your memorization technique here (text or link)"
              :rows="5"
              v-model="myMnemonic.mnemonic"
            >
            </el-input>
          </el-tab-pane>
          <el-tab-pane name="second" :disabled="true">
            <span slot="label"
              ><i class="fas fa-image"></i>
              {{ $t("flashCards.Images/Video") }}</span
            >
            <div @click.stop="">
              <div
                v-if="
                  myMnemonic.mnemonic_img_url &&
                    myMnemonic.mnemonic_img_url !== ''
                "
              >
                <img
                  v-if="isImage(myMnemonic.mnemonic_img_url)"
                  :src="myMnemonic.mnemonic_img_url"
                  style="height: auto;max-height: 100px;max-width: 100%;"
                />
                <video
                  class="my-video"
                  v-if="isVideo(myMnemonic.mnemonic_img_url)"
                  :src="myMnemonic.mnemonic_img_url"
                  style="height:100px;width: 400px;"
                  controls
                ></video>

                <div class="mt-3">
                  <el-button type="danger" size="mini" @click="removeFile"
                    >重新选择</el-button
                  >
                </div>
              </div>
              <el-upload
                v-else
                ref="upload"
                drag
                name="file"
                :before-upload="beforeAvatarUpload"
              >
                <div slot="tip" class="el-upload__tip">
                  <b>{{
                    $t(
                      "flashCards.Images must be in jpg or png format and cannot be bigger than 5 mb"
                    )
                  }}</b>
                  <br />
                  <b>{{
                    $t(
                      "flashCards.Videos must be in mp4/avi/wmv/mov format and cannot be bigger than 5 mb"
                    )
                  }}</b>
                </div>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  {{ $t("flashCards.Drag and drop image/video or upload") }}
                </div>
              </el-upload>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-checkbox v-model="is_public" style="margin-top:10px">
          {{ $t("flashCards.Share with other students") }}
          &nbsp;
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              $t(
                'flashCards.our memorization technique is public to help other students remember this word'
              )
            "
            placement="top-start"
          >
            <span><i class="fas fa-question-circle"></i></span>
          </el-tooltip>
        </el-checkbox>
        <!-- &nbsp;
        <el-checkbox
          v-model="is_hidden"
          style="margin-top:10px"
          :disabled="!is_public"
        >
          {{ $t("flashCards.Share it anonymously") }}
          &nbsp;
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              $t(
                'flashCards.You can share your technique anonymously if you’re shy'
              )
            "
            placement="top-start"
          >
            <span><i class="fas fa-question-circle"></i></span>
          </el-tooltip>
        </el-checkbox> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="danger"
          size="small"
          @click="deleteMnemonic(myMnemonic.id)"
          v-if="myMnemonic.id && myMnemonic.id > 0"
        >
          {{ $t("courses.delete") }}
        </el-button>
        <el-button type="primary" size="small" @click="saveMnemonic">
          {{ $t("courses.save") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import VocabList from "@/apis/myVocabLists";
import FlashCards from "@/apis/flashcards";
import NotesList from "@/views/flashCards/components/NotesList.vue";
import JwtService from "@/common/jwt.service";
import Mnemonics from "@/views/flashCards/components/Mnemonics.vue";
import Mnemonic from "@/views/flashCards/components/Mnemonic.vue";
import mixins from "@/views/flashCards/mixins/index.js";
import heic2any from "heic2any";
import Flashcards from "@/apis/flashcards";

import _ from "lodash";
export default {
  metaInfo() {
    return {
      title: this.title + " - " + this.CompanyName
    };
  },
  components: { NotesList, Mnemonic },
  mixins: [mixins],
  props: ["isFlashCard"],
  data() {
    return {
      upLoading: false,
      title: "",
      showEditNote: false,
      showNotes: false,
      starDialogVisible: false,
      activeList: {
        id: 0,
        title: "",
        intro: ""
      },
      starDrawer: false,
      finished: false,
      currentSelectedContent: [],
      totalLength: NaN,
      originLength: NaN,
      showDetail: false,
      show: false,
      startTime: Date.now(),
      starSelected: false,
      starHover: false,
      isLeaveFalshcard: false,
      isHamburgerActive: false,
      drawer: false,
      widthPercent: "30%",
      widthPercentDialog: "60%",
      finishRemove: true,
      vocab_backs: [],
      currentRecordVocab: this.defaultRecord(),
      currentTimeSecond: 0,
      isOvertime: false,
      timer: null,
      dialogVisible: false,
      totalTime: 0,
      totalHour: 0,
      totalMinute: "09",
      totalSecond: "12",
      lists: [],
      utterThis: new SpeechSynthesisUtterance(""),
      synth: window.speechSynthesis,
      openWord: false,
      openNote: false,
      mnemonics: [],
      myMnemonic: {
        mnemonic: "",
        mnemonic_img_url: "",
        is_public: 1,
        is_hidden: 0
      },
      myMnemonics: [],
      is_hidden: false,
      is_public: true,
      is_anonymously: false,
      viewWordList: [],
      viewTotal: 0
    };
  },
  computed: {
    ...mapState("user", ["profile", "lang"]),
    showStared() {
      return this.currentSelectedContent[0]?.user_favorite;
    }
  },
  watch: {
    is_public(val) {
      if (val) {
        this.myMnemonic.is_public = 1;
      } else {
        this.myMnemonic.is_public = 0;
      }
    },
    is_hidden(val) {
      if (val) {
        this.myMnemonic.is_hidden = 1;
      } else {
        this.myMnemonic.is_hidden = 0;
      }
    },
    totalLength(newVal) {
      if (newVal === 0) {
        // 將資料傳回後端
        // this.$store.dispatch("flashcards/recordVocab", {
        //   vocab_backs: this.vocab_backs
        // });
        this.calculateTimeFormat();
        this.finished = true;
        this.show = true;
      }
    },
    // 如果 isLeaveFalshcard 有變化則呼叫此 method，此時使用者尚未離開，瀏覽器上方會出現 alert 詢問使用者是否確定離開
    isLeave(newVal) {
      if (newVal) {
        // 因為無法知道使用者是否會選擇離開，因此開始新的計時
        this.startTime = Date.now();
        this.isLeaveFalshcard = false;
      }
    }
  },

  async created() {
    if (this.$route.query.selectedList) {
      const res = await Flashcards.getWords({
        word_list_ids: this.$route.query.selectedList.split(",")
      });
      this.currentSelectedContent = res;
    }
    this.title = this.$route.params.title;

    if (this.$route.query.selectedList) {
      this.shuffleArray(this.currentSelectedContent);
    }
    this.totalLength = this.currentSelectedContent.length;
    this.originLength = this.currentSelectedContent.length;

    // listen to current size of screen
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 400) {
        this.widthPercent = "50%";
        // this.widthPercentDialog = "70%";
      } else if (this.windowWidth < 800) {
        this.widthPercent = "30%";
        // this.widthPercentDialog = "50%";
      } else {
        this.widthPercent = "30%";
        // this.widthPercentDialog = "30%";
      }
    });

    // timer - 持續記錄時間，由 currentTimeSecond 控制，只要在其他地方設 currentTimeSecond 為 0
    // 此 timer 就會從頭開始計算
    // this.timer = setInterval(myTimer, 1000);
    // const thisVar = this;
    // function myTimer() {
    //   if (thisVar.currentTimeSecond / 1000 >= 120 && !thisVar.finished) {
    //     thisVar.dialogVisible = true;
    //     thisVar.isOvertime = true;
    //   }
    //   thisVar.currentTimeSecond += 1000;
    // }
  },
  mounted() {
    this.$nextTick(() => {
      document.getElementById("box").addEventListener("click", () => {
        this.startTime = Date.now();
        this.currentTimeSecond = 0;
      });
    });
    this.getStarWords();
    const main = document.getElementsByClassName("el-main");
    main[0].style.padding = "0";
    // 監視是否關閉視窗
    window.onbeforeunload = () => {
      // 因為瀏覽器版本緣故，目前只能塞少量指令在 onbeforeunload 裡 (還不知道具體原因)
      // 因此只放 isLeave，由 watch 監聽 isLeaveFalshcard 的變化再做 post 處理
      this.isLeaveFalshcard = true;
      return "Are you sure you want to leave?";
    };
    // this.getLists();
  },
  beforeDestroy() {
    // 如果 totalLength 是 0 代表已經結束，因此不需要再 call 一次 post API
    // 如果 totalLength 不小於初始長度，則不需要 call API
    if (
      this.totalLength !== 0 &&
      this.totalLength < this.getCurrentSelectedVocabs.length
    ) {
      this.$store.dispatch("flashcards/recordVocab", {
        vocab_backs: this.vocab_backs
      });
    }
    if (window.innerWidth > 992) {
      // 離開此頁面後將 padding 恢復原狀
      const main = document.getElementsByClassName("el-main");
      main[0].style.padding = "100px 0px 0px 0px";
    } else {
      const main = document.getElementsByClassName("el-main");
      main[0].style.padding = "60px 0px 0px 0px";
    }
    // 不再監聽 onbeforeunload
    window.onbeforeunload = null;
    // 清除 timer
    clearInterval(this.timer);
  },
  methods: {
    async starWord(wordId) {
      await FlashCards.starWord(wordId);
      this.currentSelectedContent[0].user_favorite = !this
        .currentSelectedContent[0].user_favorite;
    },
    async addMnemonics(wordId, string) {
      await FlashCards.addMnemonics(wordId, {
        mnemonic: string
      });
    },
    async getStarWords() {
      await FlashCards.getStarWords();
    },
    async getAIMnemonics() {
      const res = await FlashCards.getAIMnemonics(
        this.currentSelectedContent[0].id
      );
      this.myMnemonic.mnemonic = res.content;
    },
    saveMnemonic() {
      if (
        (this.myMnemonic.mnemonic && this.myMnemonic.mnemonic !== "") ||
        (this.myMnemonic.mnemonic_img_url &&
          this.myMnemonic.mnemonic_img_url !== "")
      ) {
        if (this.myMnemonic && this.myMnemonic.id > 0) {
          this.updateMnemonic(this.myMnemonic.id);
        } else {
          this.createMnemonic();
        }
      } else {
        this.$message.warning(
          `${this.$t("flashCards.Text")}/${this.$t(
            "flashCards.Images/Video"
          )}${this.$t("flashCards.required")}`
        );
      }
    },
    async createMnemonic() {
      const res = await FlashCards.addMnemonics(
        this.currentSelectedContent[0].id,
        {
          mnemonic: this.myMnemonic.mnemonic
        }
      );
      this.getMnemonics();
      this.showEditNote = false;
    },
    async updateMnemonic(id) {
      const res = await FlashCards.updateMnemonic(id, {
        mnemonic: this.myMnemonic.mnemonic
        // mnemonic_img_url: this.myMnemonic.mnemonic_img_url,
        // is_public: this.myMnemonic.is_public,
        // is_hidden: this.myMnemonic.is_hidden
      });
      this.getMnemonics();
      this.showEditNote = false;
    },
    async deleteMnemonic(id) {
      this.$confirm("This will delete it forever, Continue?", "Confirmation", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "error"
      }).then(async () => {
        await FlashCards.deleteMnemonic(id);
        this.getMnemonics();
        this.showEditNote = false;
      });
    },
    async saveLike(item) {
      await FlashCards.saveLike(item.id);
      this.getMnemonics();
      if (item.user_liked) {
        item.user_liked = false;
        if (item.user_liked_count > 0) {
          item.user_liked_count = item.user_liked_count - 1;
        }
      } else {
        item.user_liked = true;
        item.user_liked_count = item.user_liked_count + 1;
      }
    },
    async getMnemonics() {
      console.log(this.currentSelectedContent[0]);
      const res = await FlashCards.getMnemonics(
        this.currentSelectedContent[0].id
      );
      this.mnemonics = res.word_mnemonics;
      let myMnemonics = [];
      let mnemonics = [];
      this.mnemonics.forEach((val, index) => {
        if (val.user_id === this.profile.id) {
          myMnemonics.push(val);
        } else {
          mnemonics.push(val);
        }
      });
      this.myMnemonics = myMnemonics;
      this.myMnemonics = _.orderBy(
        this.myMnemonics,
        ["user_liked", "user_liked_count"],
        ["desc", "desc"]
      );
      this.mnemonics = mnemonics;
      this.mnemonics = _.orderBy(
        this.mnemonics,
        ["user_liked", "user_liked_count"],
        ["desc", "desc"]
      );
    },
    beforeAvatarUpload(file) {
      let fileType = this.checkFile(file.name);
      const isLt5M = file.size / 1024 / 1024 < 5;
      const isImage = fileType === "image";
      const isVideo = fileType === "video";
      if (!(isImage || isVideo)) {
        this.$message.error(
          this.$t(
            "flashCards.Please upload the image or video in the right format!"
          )
        );
      }
      if (!isLt5M) {
        this.$message.error(
          this.$t("flashCards.The image or video cannot be bigger than 5 mb!")
        );
      }
      let canUpload = false;
      canUpload = (isImage || isVideo) && isLt5M;
      if (canUpload) {
        this.upLoading = true;
        this.getImg(file);
      }
      return false;
    },
    getTodictionaryLink(dictionary) {
      let word = this.currentSelectedContent[0].word;
      switch (dictionary) {
        case "youtube":
          return `https://www.youtube.com/results?search_query=${word}`;
        case "google":
          return `https://www.google.com/search?q=${word}&tbm=isch&sclient=img`;
        case "cambridge":
          return `https://dictionary.cambridge.org/zht/%E8%A9%9E%E5%85%B8/%E8%8B%B1%E8%AA%9E-%E6%BC%A2%E8%AA%9E-%E7%B9%81%E9%AB%94/${word}`;
        case "merriam-webster":
          return `https://www.merriam-webster.com/dictionary/${word}`;
        default:
      }
    },
    editMyMnemonic(index) {
      this.myMnemonic = this.myMnemonics[index];
      this.showEditNote = true;
    },
    createNewMnemonic() {
      this.showEditNote = true;
      this.myMnemonic = {
        mnemonic: "",
        mnemonic_img_url: "",
        is_public: 1,
        is_hidden: 0
      };
    },
    removeFile(file, fileList) {
      this.myMnemonic.mnemonic_img_url = null;
    },
    getImg(file) {
      let fileNameExt = file.name.substr(file.name.lastIndexOf(".") + 1);
      let fileName = file.name.substring(0, file.name.lastIndexOf("."));
      if (fileNameExt == "heic" || fileNameExt == "HEIC") {
        heic2any({
          blob: new Blob([file]), // 将heic转换成一个buffer数组的图片
          toType: "image/jpeg", //要转化成具体的图片格式，可以是png/gif
          quality: 0.4 // 图片的质量，参数在0-1之间
        }).then(result => {
          let formData = new FormData();
          formData.append("file", result, fileName + ".jpeg");
          this.uploadJpg(formData);
        });
      } else {
        let formData = new FormData();
        formData.append("file", new Blob([file]), file.name);
        this.uploadJpg(formData);
      }
    },
    async uploadJpg(formData) {
      const res = await FlashCards.uploadImg(formData);
      this.myMnemonic.mnemonic_img_url = res.url;
    },
    async getLists() {
      const res = await VocabList.getVocabLists();
      this.lists = res.vocab_collects;
    },

    defaultRecord() {
      return {
        vocab_back_id: "",
        usage_time: "",
        is_stared: ""
      };
    },
    // shuffle array
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      array.forEach(item => {
        item["hard"] = 0;
        item["good"] = 0;
        item["easy"] = 0;
      });
    },
    toggleClick() {
      document.documentElement.style.overflow = "auto";
      this.isHamburgerActive = true;
      this.drawer = true;
    },
    handleClose() {
      document.documentElement.style.overflow = "hidden";
      this.isHamburgerActive = false;
      this.drawer = false;
    },

    // 按左上角叉，將單字從 array 中移除
    removeWord() {
      this.viewTotal++;
      // 移除第一項
      const curVocab = this.currentSelectedContent.shift();
      this.viewWordList.push(curVocab);
      this.totalLength = this.currentSelectedContent.length;

      // 將目前已結束單字資料整理過後放入 vocab_backs
      this.currentRecordVocab.vocab_back_id = curVocab.vocab_back_id;
      this.currentRecordVocab.is_stared = this.starSelected ? 1 : 0;
      // // 紀錄目前時間
      // this.currentRecordVocab.usage_time = this.isOvertime
      //   ? 120
      //   : this.calculateTimeDiff();

      // // 計算總時間
      // this.totalTime += this.currentRecordVocab.usage_time;
      // this.vocab_backs.push(this.currentRecordVocab);

      // // 重置 isOvertime
      // this.isOvertime = false;
      // 重置 showDetail
      this.showDetail = false;
      // // 重置 star
      // this.starSelected = false;
      // // 重新開始計時
      // this.startTime = Date.now();
      // this.currentRecordVocab = this.defaultRecord();
      // 重置秒數
      // this.currentTimeSecond = 0;
    },
    async toggleDetail(word) {
      if (!this.showDetail) {
        // this.currentSelectedContent[0]["liked_by_me"] = null;
        // const liked = await FlashCards.likedByme(word.vocab_back_id);
        // this.currentSelectedContent[0]["liked_by_me"] = liked.liked_by_me;
        this.getMnemonics();
      }
      this.showDetail = !this.showDetail;
    },
    putToRandomPlace(level) {
      this.viewTotal++;
      // 如果是 hard 那就隨機放入前 1/3 (不包括首項，因此用 Math.ceil() 避開 0)
      if (level === "hard") {
        const firstElement = this.currentSelectedContent.shift();
        const index = Math.ceil(
          Math.random() * Math.round(this.totalLength / 3)
        );
        firstElement.hard++;
        this.currentSelectedContent.splice(index, 0, firstElement);
        // 如果是 good 那就隨機放入中間 1/3
      } else if (level === "good") {
        const firstElement = this.currentSelectedContent.shift();
        firstElement.good++;
        const index =
          Math.floor(Math.random() * Math.round(this.totalLength / 3)) +
          Math.round(this.totalLength / 3);
        this.currentSelectedContent.splice(index, 0, firstElement);
        // 如果是 easy 就隨機放入後 1/3 (直接用總數減前兩個區間的值，避免計算時出現四捨五入問題)
      } else if (level === "easy") {
        const firstElement = this.currentSelectedContent.shift();
        firstElement.easy++;
        const index =
          Math.floor(
            Math.random() *
              (this.totalLength - Math.round(this.totalLength / 3) * 2)
          ) +
          Math.round(this.totalLength / 3) * 2;
        this.currentSelectedContent.splice(index, 0, firstElement);
      }
      // 重置 showDetail
      this.showDetail = false;
      // 重置 star
      this.starSelected = false;
      // 重新開始計時
      this.startTime = Date.now();
      this.currentTimeSecond = 0;
      this.getMnemonics();
    },
    calculateTimeDiff() {
      let endTime = Date.now();
      let timeDiff = (endTime - this.startTime) / 1000;
      return Math.round(timeDiff);
    },
    goBack() {
      this.$router.back(-1);
    },
    speakWord(word, lang) {
      this.utterThis.lang = lang;
      this.utterThis.text = word;
      this.synth.speak(this.utterThis);
    },
    restart() {
      this.$router.go(0);
    },
    dialogContinue() {
      this.startTime = Date.now();
      this.currentTimeSecond = 0;
      this.dialogVisible = false;
    },
    dialogLeave() {
      this.startTime = Date.now();
      this.currentTimeSecond = 0;
      this.dialogVisible = false;
      this.goBack();
    },
    calculateTimeFormat() {
      const tempHour = Math.floor(this.totalTime / 3600);
      if (tempHour === 0) {
        this.totalHour = "00";
      } else if (tempHour < 10) {
        this.totalHour = "0" + tempHour.toString();
      } else {
        this.totalHour = tempHour.toString();
      }
      this.totalTime -= tempHour * 3600;
      const tempMinute = Math.floor(this.totalTime / 60);
      if (tempMinute === 0) {
        this.totalMinute = "00";
      } else if (tempMinute < 10) {
        this.totalMinute = "0" + tempMinute.toString();
      } else {
        this.totalMinute = tempMinute.toString();
      }
      const tempSecond = this.totalTime - tempMinute * 60;
      if (tempSecond === 0) {
        this.totalSecond = "00";
      } else if (tempSecond < 10) {
        this.totalSecond = "0" + tempSecond.toString();
      } else {
        this.totalSecond = tempSecond.toString();
      }
    },
    stared(id) {
      let stared = false;
      if (this.currentSelectedContent[0].vocab_collect_ids) {
        this.currentSelectedContent[0].vocab_collect_ids.forEach(list => {
          if (list === id) {
            stared = true;
          }
        });
      }
      return stared;
    },
    async saveWordLike(item) {
      if (item.liked_by_me === 1) {
        item.liked_by_me = 0;
        if (item.star > 0) {
          item.star = item.star - 1;
        }
      } else {
        item.liked_by_me = 1;
        item.star = item.star + 1;
      }
      await FlashCards.saveWordLike(item.vocab_back_id);
    },
    async addStar(id) {
      let arr = [];
      arr.push(this.currentSelectedContent[0].vocab_front_id);
      const res = await VocabList.addVocabToList(id, {
        vocab_front_id: arr
      });
      if (this.currentSelectedContent[0].vocab_collect_ids) {
        this.currentSelectedContent[0].vocab_collect_ids.push(id);
      } else {
        let new_arr = [];
        new_arr.push(id);
        this.currentSelectedContent[0].vocab_collect_ids = new_arr;
      }
      this.$notify({
        message: "已收藏。",
        position: "bottom-right",
        duration: 1000,
        offset: 80
      });
    },
    async removeStar(id) {
      const res = await VocabList.deleteVocabFromList(
        id,
        this.currentSelectedContent[0].vocab_front_id
      );
      this.currentSelectedContent[0].vocab_collect_ids.splice(
        this.currentSelectedContent[0].vocab_collect_ids.indexOf(id),
        1
      );
      this.$notify({
        message: "已取消收藏。",
        position: "bottom-right",
        duration: 1000,
        offset: 80
      });
    },
    showNewList() {
      this.starDialogVisible = true;
      this.activeList = {
        id: 0,
        title: "",
        intro: ""
      };
    },
    async createList() {
      if (this.activeList.title !== "") {
        await VocabList.createVocabList({
          title: this.activeList.title,
          intro: this.activeList.intro
        });
        this.getLists();
        this.starDialogVisible = false;
      } else {
        this.$message({
          message: "`Title` 不能為空！",
          type: "warning"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/navbarFlashcard.scss";
.box {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.words-cards-box {
  padding: 3rem 1rem 1rem 3rem;
  padding: 1rem;
  flex: 1;
  overflow: hidden;
}
.words-cards {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 1rem;
  border: 1px solid #ebeef5;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.words-cards-bottom {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  border: 1px solid #ebeef5;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  z-index: -1;
}
.section-title {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
::v-deep .my-video {
  margin-top: 20px;
  width: 400px;
}
.subTitle .el-divider__text.is-center {
  font-size: 1rem;
  color: #8d8d8d;
  padding: 0 10px;
}

::v-deep .word-finished .el-tabs__item {
  padding: 0 !important;
}
.word-finished li {
  font-size: 18px;
  font-weight: 500;
}
.card-line {
  padding: 0 20px;
  height: 38px;
}
::v-deep .el-tabs__item.is-active .hard-cards {
  border-bottom: 2px solid rgb(217, 83, 79);
  color: rgb(217, 83, 79);
}
::v-deep .el-tabs__item.is-active .good-cards {
  border-bottom: 2px solid rgb(91, 192, 222);
  color: rgb(91, 192, 222);
}
::v-deep .el-tabs__item.is-active .easy-cards {
  border-bottom: 2px solid rgb(66, 161, 106);
  color: rgb(66, 161, 106);
}
::v-deep .el-tabs__item.is-active .total-cards {
  border-bottom: 2px solid #303133;
  color: #303133;
}
.word-finished {
  flex: 1;
  padding: 1rem;
}

.list-content {
  display: flex;
  justify-content: space-between;
}
.list-content .list-title {
  font-size: 18px;
  font-weight: 500;
}
.starLists {
  padding: 0 20px;
}
.el-button {
  outline: none;
}
.flashcardHeader {
  display: flex;
  height: 55px;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  border-top: 1px solid #e0e0e0;
  color: black;
  font-size: 28px;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.flashcardHeaderSecond {
  height: 22px;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  // border-bottom: 1px solid #e0e0e0;
  color: black;
  font-size: 14px;
  font-weight: 400;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.return:hover {
  opacity: 60%;
}
.return {
  width: 40px;
  margin-left: 1rem;
}

.headerTitle {
  text-align: center;
  width: 100%;
  font-weight: 600;
}
.remaining-progress {
  margin-right: 1rem;
}
.word-section {
  margin-bottom: 1rem;
}
.controlRow {
  display: flex;
  height: 55px;
  justify-content: space-between;
  align-items: center;
}

.remove {
  display: flex;
  font-size: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #e0e0e0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 1rem;
  // width: 20%;
}
.remove:hover {
  background-color: #fafafa;
}
.words-button {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}
.words-button h1 {
  font-size: 1.5rem;
  margin: 0 0.5rem;
}

.star {
  width: 20%;
  font-size: 2rem;
  text-align: right;
  font-weight: 400;
}

.starHover {
  color: black;
  cursor: pointer;
}

.starHover:hover {
  color: orange;
}

.starIsSelected {
  color: orange;
  cursor: pointer;
}
.mnemonics-cards-list {
  display: flex;
  overflow: auto;
  padding: 0.5rem;
}

.wordRemaining {
  // position: fixed;
  text-align: right;
  height: 48px;
  line-height: 48px;
  font-weight: 500;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.wordDisplay {
  flex: 1;
  cursor: pointer;
  overflow: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  // font-weight: 600;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.wordDetail {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  line-height: 1.3;
  // font-weight: 500;
}

.exButton {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #e8f5e9;
  color: var(--themeColor);
  padding: 2px 6px;
}
.note-card-mnemonics {
  font-size: 1rem;
  padding: 0 10px;
  display: flex;
  background-color: #ecf6f0;
  color: var(--themeColor);
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  width: 30rem;
}
.note-card {
  font-size: 1rem;
  padding: 0 10px;
  // display: flex;
  background-color: #ecf6f0;
  border-radius: 4px;
}
.note-card-plus {
  display: flex;
  font-size: 16px;
  // padding: 0 10px;
  color: var(--themeColor);
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  height: 20rem;
  margin-right: 1rem;
}
.note-card-plus div {
  width: 20rem;
}
.note-star {
  text-align: center;
  margin: auto 0;
  width: 30px;
  font-size: 18px;
}
.word-example {
  font-size: 1rem;
}

.note-star .thumbs:hover {
  color: orange;
}

::v-deep .el-dialog__wrapper {
  width: 95%;
}
::v-deep .el-divider--vertical {
  height: 100%;
  margin: 0 10px;
}
::v-deep .el-dialog__body {
  padding: 10px 20px 10px 20px;
}
::v-deep .el-dialog__title {
  font-weight: 500;
}

::v-deep .el-drawer__body {
  overflow: auto;
}

// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 2s;
// }
// .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   opacity: 0;
// }

.levelButtons {
  background-color: white;
  width: 100%;
  -user-select: none;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.flex-buttons {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.btnBar {
  height: 10px;
}

.btnStyle {
  font-size: 26px;
  border: 1px solid #e0e0e0;
  padding-bottom: 12px;
  font-weight: 600;
  background: white;
}

.btnClick {
  padding-top: 8px;
  cursor: pointer;
}

.drawerItem {
  cursor: pointer;
}

.drawerItem:hover {
  background-color: #e8f5e9;
}
.voice {
  margin-top: 40px;
  font-size: 20px;
}
.note-list {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-divider__text.is-left {
  left: 0;
  font-size: 16px;
}
::v-deep .el-divider__text {
  padding-left: 0;
  color: var(--themeColor);
}
.demo-block-control {
  margin-top: 10px;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;
  color: var(--themeColor);
  cursor: pointer;
  position: relative;
}
.openModal {
  background: #f9f9f9;
  margin: 10px -20px 0px;
  padding: 10px 20px 5px;
}
.note-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  justify-content: center;
}
.note-content small {
  font-weight: normal;
  margin-top: 0;
  display: inline-block;
}
::v-deep .el-divider {
  margin-bottom: 32px;
}
::v-deep .el-divider__text.is-left {
  left: 0;
  font-size: 20px;
}
::v-deep .el-divider__text {
  padding-left: 8px;
  color: var(--themeColor);
}
::v-deep .el-progress__text {
  font-weight: 700;
  font-size: 0.9rem !important;
}
.btnStyle:hover {
  opacity: 0.5;
}
.dictionaries {
  display: flex;
  color: var(--themeColor);
}

.dictionaries .dictionary {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 4px;
}

.dictionaries .dictionary .dictionaryIcon {
  width: 2.5rem;
  height: 2.5rem;
}

.dictionaries .dictionary:hover {
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  .note-star-foot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
  }
  .note-star-foot .thumbs {
    margin-right: 5px;
  }
  .note-star,
  .note-card,
  .word-example {
    font-size: 1rem;
  }
  ::v-deep .note-star .el-button {
    margin-top: 0;
  }
}
</style>
